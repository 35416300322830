import classNames from 'classnames';
import * as customPropTypes from 'custom-prop-types';
import PropTypes from 'prop-types';
import React from 'react';

import { LanguageProvider, useLanguageContext } from '@/design-system/contexts/LanguageContext';
import { useMarketo } from '@/hooks/useMarketo';

import styles from './MarketoForm.module.scss';

const MarketoForm = (props) => {
    const {
        baseUrl,
        munchkinId,
        formId,
        spanishId,
        successFragment,
        spanishDebugMode,
        formType,
        analytics,
        testMode,
    } = props;
    const { isSpanish } = useLanguageContext();
    const form_id = (isSpanish || spanishDebugMode) && spanishId ? spanishId : formId;
    const [submitSuccess, setSubmitSuccess] = React.useState(false);

    useMarketo({
        baseUrl,
        munchkinId,
        formId: form_id,
        callbacks: {
            onSuccess: (values, followUpUrl) => {
                setSubmitSuccess(true);
            },
        },
        analytics,
        testMode,
    });
    return (
        <LanguageProvider>
            <div
                className={classNames(
                    styles[`marketo-form`],
                    formType && styles[`marketo-form--${formType}`],
                )}
            >
                {submitSuccess ? (
                    successFragment ? (
                        <div className={styles[`marketo-form__success`]}>{successFragment}</div>
                    ) : (
                        <></>
                    )
                ) : (
                    <form
                        id={`mktoForm_${form_id}`}
                        data-form-id={form_id}
                        data-form-instance={Date.now()}
                        data-form-type={formType}
                    ></form>
                )}
            </div>
        </LanguageProvider>
    );
};

MarketoForm.propTypes = {
    /**
     * URL to the Marketo server instance
     */
    baseUrl: PropTypes.string,
    /**
     * Munchkin ID
     */
    munchkinId: PropTypes.string,
    /**
     * marketo Form Id
     */
    formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * spanish Form Id
     */
    spanishFormId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * successFragment
     */
    successFragment: PropTypes.node,
    /**
     * analytics
     */
    analytics: customPropTypes.analyticsPropType,
};

export default MarketoForm;
